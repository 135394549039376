<template>
  <div
    class="content d-flex flex-column flex-column-fluid new-service"
    id="f-book-now"
    style="width: calc(90vw - 20%) !important;padding:0"
  >
    <div class="full-load" v-if="saveDataLoader">
      <h1 v-html="loadingMessage"></h1>
    </div>
    <div class="d-flex flex-column-fluid">
      <div class="container-fluid mb-25">
        <div class="row mb-3">
          <div class="col-lg-10 col-md-12 col-12 offset-0 mb-5">
            <h1>Appointment no: {{ $route.params.app_id }}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 f-payment">
            <div
              v-if="errorMsg != ''"
              role="alert"
              class="
                alert alert-custom alert-danger-dim alert-shadow
                fade
                show
                gutter-b
                alert-fixed
              "
            >
              <div class="alert-text">
                {{ errorMsg }}
              </div>
            </div>
            <f-payment :front_status="false" :is_both_card="false"></f-payment>

            <!-- <div class="row mt-10">
              <div class="col-lg-7 col-md-5 col-sm-7 col-4"></div>
              <div class="col-lg-5 col-md-5 col-sm-5 col-8">
                <button class="btn btn-danger mr-5 ml-5" @click="goback">
                  Back
                </button>
                <button class="btn btn-success" @click="storeAdditionalService">
                  Save
                </button>
              </div>
            </div> -->
          </div>
          <div
            id="book-footer-temp"
            class="bottom-nav col-lg-12 col-md-12 col-12"
            style="position: fixed; bottom: 20px; width: calc(74vw)"
          >
            <div class="card">
              <div class="card-body">
                <div>
                  <div class="total_amount">
                    <label for="">
                      <small>Total</small>
                    </label>
                    <h3 class="footer-total-amount">
                      ${{ getTotalAdditionalAmount | toFixedTwoDigit }}
                    </h3>
                  </div>
                  <button
                    class="btn btn-dark mr-2 mt-3 btn-right"
                    @click="goback"
                  >
                    Back
                  </button>
                  <button
                    class="btn btn-success mr-2 mt-3 btn-right"
                    @click="SubmitService"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import fBookNow from "@/pages/frontend/booknow/booknow.vue";
import fPayment from "@/pages/frontend/booknow/payment.vue";

import { mapGetters } from "vuex";
import {
  FETCH_NOTES,
  STORE_ADDITIONAL_SERVICE,
  CHECK_F_ABBREVIATION_CODE,
  FETCH_F_CLIENT_RATE,
  FETCH_COUNTRIES,
  FETCH_STATES,

} from "@/core/services/store/actions.type";
import {
  SET_F_CLIENT_ENABLE_ON_SITE_PAYMENT,
  SET_PAYMENT_CARD_PROPERTY,
  SET_PAYMENT_CARD_TYPE,
  SET_PAYMENT_CARD_NUMBER,
  SET_PAYMENT_CARD_HOLDER_NAME,
  SET_PAYMENT_CARD_ADDRESS,
  SET_PAYMENT_CARD_CITY,
  SET_PAYMENT_CARD_CVV_NUMBER,
  SET_PAYMENT_CARD_EXPIRY_DATE,
  CARD_COUNTRY,
  CARD_STATE,
  SET_PAYMENT_CARD_NUMBER_PHONE,
  SET_PAYMENT_CARD_NUMBER_POSTAL,
  SET_RATE_TOTAL_AMOUNT,
  UPDATE_ABBREVIATION_CODE,
  UPDATE_PAYMENT_TYPE,
  SET_ADD_RATE_QTY
} from "@/core/services/store/mutations.type";

export default {
  components: {
    fPayment
  },
  computed: {
    ...mapGetters([
      "getServiceId",
      "getAddQty",
      "getfClientRate",
      "getTotalRateAmount",
      "getAbbreviationCode",
      "updateAbbreviationCode",
      "getPaymentCardHolderName",
      "getPaymentCardExpiry",
      "getPaymentCardCvvNumber",
      "getPaymentCardType",
      "getPaymentCardHolderAddress",
      "getPaymentCardHolderCountry",
      "getPaymentCardHolderState",
      "getPaymentCardHolderCity",
      "getPaymentCardPhonecode",
      "getPaymentCardPhone",
      "getPaymentCardPostalZip",
      "getPaymentType",
      "getIsHeartsPayment",
      "getTotalAdditionalAmount"
    ])
  },
  mounted() {
    if (this.getServiceId == 0 && this.getServiceId != this.$route.params.id) {
      this.$router.go(-1);
    }
    this.$store.commit(
      SET_F_CLIENT_ENABLE_ON_SITE_PAYMENT,
      this.$route.params.onsite
    );
    this.$store.dispatch(FETCH_NOTES);
    if (this.getServiceId != "") {
      this.fetchRate();
      if (this.updateAbbreviationCode != "") {
        this.abree_code = this.updateAbbreviationCode;
        this.checkAbbreviationCode();
      }
    }
    this.$store.commit(SET_ADD_RATE_QTY, 0);
    // this.$store.commit(SET_RATE_TOTAL_AMOUNT, 0);
    this.$store.commit(SET_PAYMENT_CARD_PROPERTY, "");
    this.$store.commit(SET_PAYMENT_CARD_TYPE, "");
    this.$store.commit(SET_PAYMENT_CARD_NUMBER, "");
    this.$store.commit(SET_PAYMENT_CARD_HOLDER_NAME, "");
    this.$store.commit(SET_PAYMENT_CARD_ADDRESS, "");
    this.$store.commit(SET_PAYMENT_CARD_CITY, "");
    this.$store.commit(SET_PAYMENT_CARD_CVV_NUMBER, "");
    this.$store.commit(SET_PAYMENT_CARD_EXPIRY_DATE, "");
    this.$store.commit(CARD_COUNTRY, "US");
    this.$store.commit(CARD_STATE, "");
    this.$store.commit(SET_PAYMENT_CARD_NUMBER_PHONE, "");
    this.$store.commit(SET_PAYMENT_CARD_NUMBER_POSTAL, "");
    this.$store.dispatch(FETCH_COUNTRIES);
    this.$store.dispatch(FETCH_STATES);
  },
  data() {
    return {
      errorMsg: "",
      dataLoading: false,
      loadingMessage: "",
      saveDataLoader: false
    };
  },
  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    }
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    fetchRate() {
      this.loadingData = true;
      var id = this.$route.params.id;
      var service_id = this.getServiceId;

      this.$store
        .dispatch(FETCH_F_CLIENT_RATE, {
          id: id,
          service_id: service_id
        })
        .then(data => {
          this.rateData = data;
          this.loadingData = false;
          // this.calAdditional();
          var add_rate = parseFloat(this.getfClientRate.additional_rate);
          var tax_percentage = parseFloat(this.getfClientRate.tax_percentage);

          this.$store.commit(
            SET_RATE_TOTAL_AMOUNT,
            (1 * add_rate + (1 * add_rate * tax_percentage) / 100).toFixed(2)
          );
        })
        .catch(() => {
          this.loadingData = false;
        });
    },
    checkAbbreviationCode() {
      this.$store.commit(UPDATE_ABBREVIATION_CODE, this.abree_code);
      if (this.updateAbbreviationCode != "") {
        this.abbreviation_code_error = true;

        this.$store
          .dispatch(CHECK_F_ABBREVIATION_CODE, {
            code: this.updateAbbreviationCode,
            client_slug: this.$route.params.client_slug
          })
          .then(data => {
            if (data.success) {
              this.paymentMethod = data.data.show_payment_option;
              if (!data.data.show_payment_option) {
                this.$store.commit(UPDATE_PAYMENT_TYPE, "A");
              }
              this.abree_code = "";
            } else {
              this.paymentMethod = true;
            }
          });
      } else {
        this.abbreviation_code_error = false;
      }
    },
    SubmitService() {
      var mytoken = document.getElementById("mytoken");

      mytoken = mytoken.innerHTML;
      if (this.updateAbbreviationCode != "") {
        if (!this.getAbbreviationCode.success) {
          this.$toastr.e(this.getAbbreviationCode.msg);
        } else {
          if (this.getPaymentType == "C") {
            this.loadingMessage =
              "please wait <br> While we process your payment...";

            if (mytoken == "" || mytoken == null) {
              this.$toastr.e(
                "The card number entered is not compliant, please check and re-enter."
              );
            } else {
              if (this.getIsHeartsPayment) {
                var expiremonth = document.getElementById("expiremonth")
                  .innerHTML;
                var expireyear = document.getElementById("expireyear")
                  .innerHTML;

                if (
                  mytoken == "" ||
                  mytoken == null ||
                  expiremonth == "" ||
                  expiremonth == null ||
                  expireyear == "" ||
                  expireyear == null
                  // this.getPaymentCardCvvNumber == ""
                ) {
                  this.$toastr.e("Card info is required");
                } else {
                  this.SubmitForm();
                }
              } else {
                if (
                  mytoken == "" ||
                  mytoken == null ||
                  this.getPaymentCardHolderName == "" ||
                  this.getPaymentCardExpiry == "" ||
                  this.getPaymentCardCvvNumber == ""
                ) {
                  this.$toastr.e("Card info is required");
                } else {
                  this.SubmitForm();
                }
              }
            }
          } else {
            this.loadingMessage =
              "please wait <br> Your appointment is being scheduled...";

            this.SubmitForm();
          }
        }
      } else {
        if (this.getPaymentType == "C") {
          this.loadingMessage =
            "please wait <br> While we process your payment...";
          if (mytoken == "" || mytoken == null) {
            this.$toastr.e(
              "The card number entered is not compliant, please check and re-enter."
            );
          } else {
            if (this.getIsHeartsPayment) {
              var expiremonth2 = document.getElementById("expiremonth")
                .innerHTML;
              var expireyear2 = document.getElementById("expireyear").innerHTML;

              if (
                mytoken == "" ||
                mytoken == null ||
                expiremonth2 == "" ||
                expiremonth2 == null ||
                expireyear2 == "" ||
                expireyear2 == null
                // this.getPaymentCardCvvNumber == ""
              ) {
                this.$toastr.e("Card info is required");
              } else {
                this.SubmitForm();
              }
            } else {
              if (
                mytoken == "" ||
                mytoken == null ||
                this.getPaymentCardHolderName == "" ||
                this.getPaymentCardExpiry == "" ||
                this.getPaymentCardCvvNumber == ""
              ) {
                this.$toastr.e("Card info is required");
              } else {
                this.SubmitForm();
              }
            }
          }
        } else {
          this.loadingMessage =
            "please wait <br> Your appointment is being scheduled...";
          this.SubmitForm();
        }
      }
    },
    SubmitForm() {
      this.errorMsg = "";
      let updateAbbreviationCode = "";
      if (this.getAbbreviationCode.data.length == 0) {
        updateAbbreviationCode = "";
      } else {
        updateAbbreviationCode = this.updateAbbreviationCode;
      }
      this.saveDataLoader = true;
      var mytoken = document.getElementById("mytoken");
      var formData = {
        type: this.getPaymentType,
        appointment_id: this.$route.params.app_id,
        abbreviation_code: updateAbbreviationCode,
        sms_status: false,
        appt_token: this.$route.params.token,
        qty: this.getAddQty
      };
      if (this.getAddQty == 0) {
        this.$toastr.w("Please add atleast one card");
        this.saveDataLoader = false;
        return;
      }

      let token = mytoken.innerHTML;
      if (this.getPaymentType == "C") {
        if (this.getIsHeartsPayment) {
          var expiremonth = document.getElementById("expiremonth");
          var expireyear = document.getElementById("expireyear");
          var token_type = document.getElementById("token_type");
          var last_four = document.getElementById("last_four");
          var card_type = document.getElementById("card_type");
          formData.payment_details = {
            hearts_token: token,
            expire_month: expiremonth.innerHTML,
            expire_year: expireyear.innerHTML,
            // card_cvv_no: this.getPaymentCardCvvNumber,
            postal: this.getPaymentCardPostalZip,
            token_type: token_type.innerHTML,
            last_four: last_four.innerHTML,
            card_type: card_type.innerHTML,
            card_address: this.getPaymentCardHolderAddress
          };
        } else {
          formData.payment_details = {
            card_number: token,
            card_holder_name: this.getPaymentCardHolderName,
            card_expiry: this.getPaymentCardExpiry,
            card_cvv_no: this.getPaymentCardCvvNumber,
            card_type: this.getPaymentCardType,

            card_address: this.getPaymentCardHolderAddress,
            card_city: this.getPaymentCardHolderCity,
            card_country: this.getPaymentCardHolderCountry,
            card_state: this.getPaymentCardHolderState,
            phone:
              this.getPaymentCardPhonecode.dial_code + this.getPaymentCardPhone,
            postal: this.getPaymentCardPostalZip
          };
        }
      }

      this.$store
        .dispatch(STORE_ADDITIONAL_SERVICE, {
          formData
        })
        .then(res => {
          if (res.code == 200) {
            this.$toastr.s(res.msg);
            this.$router.go(-1);
          }
          this.saveDataLoader = false;
        })
        .catch(errs => {
          if (errs.msg == "") {
            this.$toastr.e("oops something went wrong");
            return;
          }
          this.$toastr.e(errs.msg);
          this.errorMsg = errs.msg;
          this.saveDataLoader = false;
        });
    },
    storeAdditionalService() {
      let data = {
        appointment_id: this.$route.params.app_id,
        qty: this.getAddQty,
        appt_token: this.$route.params.token
      };
      this.$store
        .dispatch(STORE_ADDITIONAL_SERVICE, {
          data
        })
        .then(res => {
          // console.log(res);
          if (res.code == 200) {
            this.$toastr.s(res.msg);
            this.$router.go(-1);
          }
        })
        .catch(errs => {
          this.$toastr.e("oops something went wrong");
          this.errorMsg = errs.msg;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.alert-danger-dim {
  background: #f7cece90 !important;
}
#book-footer-temp {
  .btn {
    height: 40px !important;
    float: right;
  }
  .total_amount {
    display: inline-block;
    .btn-right {
      float: right !important;
    }
  }
}
.alert-custom {
  /* width: 78%; */
  /* position: relative; */
  /* top: -15%; */
  /* left: 4%; */
  /* margin-left: 12%; */
  margin-top: 5%;
  z-index: 1;
  background: #dc3545 !important;
}
</style>
